import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import NavTags from '../components/NavTags';
import ProjetCard from '../components/ProjetCard';
import clsx from 'clsx';
import AnimateOnScrollFramer from '../components/ui/AnimateOnScrollFramer';
import { useEffect } from 'react';
import { subscribe, unsubscribe } from 'pubsub-js';
import { useRef } from 'react';
// import { TagsWrapper } from '../contexts/TagsWrapper';

export const query = graphql`
  query Projets {
    sanityProjets {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      navTags {
        tagGroup {
          title {
            ...localeString
          }
          tags {
            _type
            slug {
              current
            }
            title {
              ...localeString
            }
          }
        }
      }
      projectsGrid {
        size
        projet {
          ...projetCard
        }
      }
    }
  }
`;

const Projets = ({ pageContext, data }) => {
  // console.log(pageContext);
  const { seo, navTags, projectsGrid } = data.sanityProjets;
  // const projectsGridOk = projectsGrid.filter((el) => el.size !== null);
  // console.log(projectsGridOk);
  const fultersRef = useRef();

  useEffect(() => {
    const tokenOpen = subscribe('SUMMARY_OPEN', (e, d) => {
      // console.log(d);
      fultersRef.current.classList.add('is-open');
    });

    const tokenClose = subscribe('SUMMARY_CLOSE', (e, d) => {
      // console.log(d);
      fultersRef.current.classList.add('is-close');
    });

    return () => unsubscribe(tokenOpen);
  }, []);

  return (
    <div className="main-area pt-_xl md:pt-xxl">
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-projets `}
          page={true}
        />
      )}

      <div className="container">
        <div
          className="pb-_md md:pb-md sticky z-10 top-0 top-header-mid- pt-header-mid filters-wrapper"
          ref={fultersRef}
        >
          <div className="absolute inset-0 background-gradient "></div>
          <NavTags input={navTags} />
        </div>
        <div className="projets-grid">
          <div className="row items-end">
            {projectsGrid.map((item, i) => (
              <div className={clsx('col col-xs-12', item.size)} key={i}>
                <AnimateOnScrollFramer className="h-full">
                  <ProjetCard input={item.projet} size={item.size || 'col-xs-12'} />
                </AnimateOnScrollFramer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projets;
